import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";
import FacebookLogin from 'react-facebook-login';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import './SocialMedia.scss';

const fbKey: string = process.env.REACT_APP_FACEBOOK_AUTH_KEY as string;
const gmailKey: string = process.env.REACT_APP_GMAIL_AUTH_KEY as string;

const SocialMediaWrapper: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const responseFacebook = (response: any) => {
    if (response.status === 'unknown') {
    } else {
      props.onSocialMediaBtnClick({ type: 'facebook', token: response.accessToken })
    }
  }

  interface GoogleLoginResponse {
    credential: string; // This will be the JWT token
    error?: string;
    accessToken: string;
  }


  const responseGoogle = (response: any) => {
    if (response.error) {
    } else {
      props.onSocialMediaBtnClick({ type: 'google', token: response.access_token })
    }
  }
  const CustomGoogleLoginButton = () => {
    // Use the Google login hook
    const login = useGoogleLogin({
      onSuccess: (response) => {
        responseGoogle(response)
      },
      onError: (error) => {
        responseGoogle(error);
      },
    });
  
    return (
      <button className="gmail-btn" onClick={() => login()}><i className="fa gmail-icon fa-google" aria-hidden="true"></i><span>Google</span></button>
    );
  };

  return (
    <Fragment>
      <h2 className="login-info">{t("login.signup")} ? </h2>
      <div className="social-media-btn-wrapper p-0">
        <div className="column column-left">
          <FacebookLogin
            appId={fbKey}
            callback={responseFacebook}
            textButton="Facebook"
            icon="fab fa-facebook"
            cssClass="fb-btn"
            isMobile={false}
          />
        </div>
        <div className="column">
          <GoogleOAuthProvider
            clientId={gmailKey}>
            <CustomGoogleLoginButton />
          </GoogleOAuthProvider>
        </div>
      </div>
    </Fragment>
  )
}

export default SocialMediaWrapper;
